/* eslint-disable no-shadow */
/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/Navbar";
import Footer from "examples/Footer";

// Soft UI Dashboard PRO React base styles
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
// Data
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SuiVectorMapRoot from "components/SuiVectorMap/SuiVectorMapRoot";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Button, Card, CircularProgress, Icon, Menu, Stack } from "@mui/material";
// import SuiInput from "components/SuiInput";
import { dateToYMD } from "common/helpers";
import Swal from "sweetalert2";
import { CustomerUserContext } from "App";
import MenuItem from "@mui/material/MenuItem";
import AppointmentTable from "components/authentication_v2/AppointmentTable";
import AppointmentKanban from "components/authentication_v2/AppointmentKanban";
import EventCalendarRoot from "components/Enrichment/components/Appointment/eventCalendar/EventCalendarRoot";
import Campaigns from "components/authentication_v2/Campaigns";
// import SuiButton from "components/SuiButton";
// import ReportChart from "examples/Charts/BarCharts_v2/ReportChart";
import MultiSelect from "components/MultiSelect";
import { saveAs } from "file-saver";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppointmentModal from "../appointments/components/AppointmentModal";
import EventCalendar from "../appointments/components/Calendar";
import CalendarModal from "./CalendarModal";
import SubscriptionLink from "./SubscriptionLink";
// import DataTable from "./components/Tables/DataTable";
// import Kanban from "./components/Kanban";

const Appointments = () => {
  // const [controller] = useSoftUIController();
  // const { miniSidenav } = controller;
  const history = useHistory();
  const [customerUser] = useContext(CustomerUserContext);
  const [today, setToday] = useState(new Date());
  const [refresh, setRefresh] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [appointmentsVectorMap, setAppointmentsVectorMap] = useState({});
  const [appointmentsDataTableValues, setAppointmentsDataTableValues] = useState({});
  const [campaign, setCampaign] = useState();
  const [campaignId, setCampaignId] = useState(0);
  //   const [switchState, setSwitchState] = useState(false);
  const [kanbanData, setKanbanData] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  // const [infoAppts, setInfoAppts] = useState([]);
  // const [loadingCampaigns, setLoadingCampaigns] = useState(true);
  const [loadingCampaignItems, setLoadingCampaignItems] = useState(true);
  const [openAppointmentModal, setOpenAppointmentModal] = useState(false);
  const [selectedApointmentId, setSelectedApointmentId] = useState(null);
  const [campaignData, setCampaignData] = useState([]);
  const [calendarLink, setCalendarLink] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [dropdownValue, setDropdownValue] = useState("");
  const [reviewStatus, setReviewStatus] = useState(() => {
    if (history.location.state?.from === "successfull") {
      return ["success"];
    }
    if (history.location.state?.from === "new appointment") {
      return ["info"];
    }
    if (history.location.state?.from === "Approved Appointments") {
      return ["warning"];
    }
    return ["info", "warning", "success", "error"];
  });
  const [reviewStatusVal, setReviewStatusVal] = useState({
    total: 0,
    info: 0,
    warning: 0,
    success: 0,
    error: 0,
  });
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [dateClicked, setDateClicked] = useState("");
  const [campaignValue, setCampaignValue] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [actionKey, setActionKey] = useState(null);

  const handleActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenAppointment = (pAppointmentId) => {
    history.push({
      pathname: "/enrichment",
      state: {
        appointmentIdParam: pAppointmentId,
        customerUser: { customerUser },
        campaignId: { campaignId },
        appointmentId: { selectedApointmentId },
        from: document.referrer,
      },
    });
    // setSelectedApointmentId(pAppointmentId);
    // setOpenAppointmentModal(true);
  };

  const handleCloseAppointmentModal = () => {
    setSelectedApointmentId(null);
    setOpenAppointmentModal(false);
  };

  //   const onSwitchChange = () => {
  //     setSwitchState(!switchState);
  //   };

  function CustomTabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  const [value, setValue] = useState(0);

  const handleClick = (id) => () => {
    setValue(id);
  };

  useEffect(() => {
    setValue(0);
  }, []);

  useEffect(() => {
    localStorage.setItem("activeTabIndex", value.toString());
  }, [value]);

  CustomTabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#FF4D00D9",
            fontFamily: "Poppins",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "-5%",
            fontSize: "13px",
            fontWeight: "600",
            width: "15%",
            paddingLeft: "5%",
            padding: "5% 7% !important",
            minHeight: "0px !important",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              marginRight: "-5%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
              width: "5%",
              paddingRight: "5%",
            },
          },
        },
      },
    },
  });

  const themeGrid = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
            boxShadow: "none",
            background: "none !important",
            minHeight: "320px",
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            borderRadius: "6px !important",
            boxShadow: "none",
            backgroundColor: "none !important",
            minHeight: "390px",
          },
        },
      },
    },
  });

  const [resset, setResset] = useState({
    cols: null,
    res: null,
  });
  const [isSubscriptionLinkOpen, setIsSubscriptionLinkOpen] = useState(false);

  const fetchAppointmentsCalendar = (generateNew = 0) => {
    const payload = {
      review_statuses: reviewStatus,
      generate_new: generateNew,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/updateCalendarICSFiletLink`,
        payload
      )
      .then((r) => {
        const { message } = r.data;
        setCalendarLink(message).then(() => {
          const payloadA = {
            review_statuses: reviewStatus,
            generate_new: 1,
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/updateCalendarICSFiletLink`,
              payloadA
            )
            .then((rA) => {
              const { messageA } = rA.data;
              setCalendarLink(messageA);
              fetchAppointmentsCalendar();
            });
        });
      })
      .catch(() => {});
  };
  const handleSubscription = () => setIsSubscriptionLinkOpen(true);

  const showAppointmentCalendar = () => {
    fetchAppointmentsCalendar();
    handleSubscription();
  };

  const fetchRes = (cols, res, pReviewStatus) => {
    setAppointmentsVectorMap([]);
    setKanbanData(null);
    let datx = [];
    if (res) {
      datx = res.data.data;
    }
    const itemsx = datx.map((d) => d.class_name).flat();

    const rSet = res.data.data.filter(
      (rv) => pReviewStatus.indexOf(rv.class_name) > -1 || pReviewStatus === undefined
    );
    const dat = rSet;

    let info = 0;
    let warning = 0;
    let success = 0;
    let error = 0;
    for (let i = 0; i < itemsx.length; i += 1) {
      // eslint-disable-next-line default-case
      switch (itemsx[i]) {
        case "info":
          info += 1;
          break;
        case "warning":
          warning += 1;
          break;
        case "success":
          success += 1;
          break;
        case "error":
          error += 1;
          break;
      }
    }
    const total = info + warning + success + error;
    setReviewStatusVal({
      total,
      info,
      warning,
      success,
      error,
    });

    const dataVal = {
      columns: cols,

      rows: dat,
    };

    setAppointmentsDataTableValues(dataVal);
    setTimeout(() => {
      setAppointmentsVectorMap(rSet);
    }, 400);

    const calendarDataVal = rSet.map((pRes) => ({
      id: `${pRes.id}`,
      title: `${pRes.lead_identificator}`,
      start: pRes.appointment_at,
      end: pRes.appointment_at,
      class_name: `${pRes.class_name}`,
      className: `${pRes.class_name}`,
      key: pRes.id,
    }));

    setCalendarData(calendarDataVal);

    setTimeout(() => {
      setKanbanData(rSet);
    }, 100);

    setLoadingCampaignItems(false);
  };

  useEffect(() => {
    if (resset.cols && resset.res) {
      fetchRes(resset.cols, resset.res, reviewStatus);
    }
    return () => {};
  }, [reviewStatus]);

  const fetchCampaignAppointmentsOrOics = (pCampaignId = 0, pReviewStatus) => {
    const campId = campaignValue.length;
    axios
      .get(
        `/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/appointments/fields`
      )
      .then((r) => {
        const cols = r.data.data;

        const appointmentsUrl =
          campId === 0
            ? `/api/customers/${customerUser.customer.id}/campaigns/0/appointments`
            : `/api/customers/${customerUser.customer.id}/campaigns/0/appointments?campaign_ids=${campaignValue}`;

        return axios.get(appointmentsUrl).then((res) => {
          setResset({
            cols,
            res,
          });
          fetchRes(cols, res, pReviewStatus);
        });
      })
      .catch(() => {
        fetchRes([], null, pReviewStatus);
        setAppointmentsDataTableValues([]);
        setAppointmentsVectorMap([]);
      });
  };

  const fetchCampaigns = () => {
    // setLoadingCampaigns(true);
    setLoadingCampaignItems(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/appointments`)
      .then((res) => {
        // setInfoAppts([
        //   {
        //     icon: { color: "primary", component: "library_books" },
        //     label: "All Leads",
        //     progress: { content: res.data.info.all_leads, percentage: 60 },
        //   },
        //   {
        //     icon: { color: "info", component: "touch_app" },
        //     label: "Imported Leads",
        //     progress: { content: res.data.info.num_of_order_request_leads, percentage: 60 },
        //   },
        //   // {
        //   //   icon: { color: "info", component: "touch_app" },
        //   //   label: "New Appointments",
        //   //   progress: { content: res.data.info.new_appointments, percentage: 90 },
        //   // },
        //   {
        //     icon: { color: "warning", component: "payment" },
        //     label: "new Messages",
        //     progress: { content: res.data.info.new_messages, percentage: 30 },
        //   },
        //   {
        //     icon: { color: "error", component: "extension" },
        //     label: "Active Campaigns",
        //     progress: { content: res.data.info.active_campaigns, percentage: 50 },
        //   },
        // ]);
        const campData = res.data.appointmentCampaigns.data;
        setCampaignData(campData);
        setCampaignValue(campData.map((campaign) => campaign.id).concat([0]));
        setCampaigns(res.data);
        // setLoadingCampaigns(false);
      })
      .catch(() => {
        setCampaigns([]);
        // setLoadingCampaigns(false);
      });
  };

  const queryParams = new URLSearchParams(window.location.search);

  const appointmentIdParam = queryParams.get("appointmentId");

  useEffect(() => {
    if (appointmentIdParam !== undefined && appointmentIdParam) {
      handleOpenAppointment(appointmentIdParam);
      // axios
      //   .get(
      //     `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${appointmentIdParam}/prospect-contacted`
      //   )
      //   .then(() => {
      //     handleOpenAppointment(appointmentIdParam);
      //   })
      //   .catch(() => {});
    }

    return () => {};
  }, [appointmentIdParam]);

  useEffect(() => {
    setToday(dateToYMD(new Date()));

    return () => {};
  }, [today]);

  useEffect(() => {
    fetchCampaigns();
    return () => {};
  }, [null, customerUser]);

  useEffect(() => {
    if (!openAppointmentModal) {
      fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
    }
    return () => {};
  }, [campaignId, customerUser, openAppointmentModal, refresh, campaignValue]);

  const [isSelected, setIsSelected] = useState("#E7E7E7");

  const handleAllCampaignsClick = () => {
    setCampaign(null);
    setCampaignId(0);
    setIsSelected("#E7E7E7");
  };

  const handleCampaignClick = (e, pCampaign) => {
    e.persist();

    setCampaign(pCampaign);
    setCampaignId(pCampaign.id);
    setIsSelected(pCampaign.id);
  };

  const handleRefreshList = () => {
    fetchCampaigns();
  };

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(campaigns?.appointmentCampaigns?.data);
  }, [campaigns]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDateClick = (arg) => {
    const clickedDate = arg.dateStr;

    const filterArray = calendarData
      ? calendarData.filter((event) => event.start.split(" ")[0] === clickedDate)
      : [];
    setFilteredEvents(filterArray);
    setDateClicked(clickedDate);
    handleOpen();
  };

  const handleFavoriteClick = (event) => {
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${event.id}/update-favorite-status`,
        {}
      )
      .then(() => {
        // Swal.fire("Success!", r.message, "success");
        fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleEventClick = (event) => {
    if (event.class_name.includes("info")) {
      Swal.fire({
        title: "Appointment Confirmation",
        text: "Do you want to confirm the appointment?",
        icon: "info",
        showCancelButton: true,
        // confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${event.id}/update-review-status`,
              context
            )
            .then(() => {
              // Swal.fire("Success!", r.message, "success");
              handleOpenAppointment(event.id);
              fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else if (event.class_name.includes("error")) {
      Swal.fire("Warning!", "You are not allowed to access the overview page", "warning");
    } else {
      handleOpenAppointment(event.id);
    }
  };

  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="40vh"
      width="100%"
    >
      <CircularProgress color="warning" /> Loading..
    </Stack>
  );
  const [search, setSearch] = useState("");
  const openAction = Boolean(anchorEl);

  const onSearchChange = (e) => {
    setSearch(e.target.value);
  };
  const [appointmentStatus, setAppointmentsStatus] = useState(false);
  const [buttonClick, setButtonClick] = useState(false);
  const [campaignsList, setCampaignsList] = useState(false);
  const [buttonClickStatus, setButtonClickStatus] = useState(false);
  const [isFavourite, setIsFavourite] = useState(0);
  const [buttonClickFavourite, setButtonClickFavourite] = useState(false);

  const handleCampaigns = () => {
    setCampaignsList(!campaignsList);
    setButtonClick(!buttonClick);
  };

  const options =
    campaignData?.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    })) || [];

  const displayValue = campaignData
    .filter((campaign) => campaignValue.includes(campaign.id))
    .map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }));

  const handleCampaignValueChange = (e) => {
    if (e.some((item) => item.label === "All")) {
      setCampaignValue(campaignData.map((item) => item.id).concat([0]));
    } else if (e.length === 0) {
      setCampaignValue([]);
    } else {
      setCampaignValue(e.map((item) => item.value));
    }
  };

  const statusOptions = [
    { value: "info", label: `New appointments(${reviewStatusVal.info})` },
    { value: "warning", label: `Approved(${reviewStatusVal.warning})` },
    { value: "success", label: `Successful(${reviewStatusVal.success})` },
  ];

  const handleExport = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(`/api/customers/${customerUser.customer.id}/campaigns/0/appointments/export`, payload, {
        responseType: "blob",
      })
      .then((res) => {
        saveAs(res.data, "appointments-export.csv");
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleExportToGoHighLevel = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/0/appointments/ghl-export`,
        payload
      )
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleExportToWebhook = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/0/appointments/webhook-export`,
        payload
      )
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleAccept = (e) => {
    e.preventDefault();
    const payload = {
      appointment_ids: selectedItems,
    };
    axios
      .post(
        `/api/customers/${customerUser.customer.id}/campaigns/0/appointments/review-state-update`,
        payload
      )
      .then((res) => {
        setRefresh(!refresh);
        Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
    setTimeout(() => {
      setDropdownValue("");
    }, 200);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };

  const statusDisplayValue = statusOptions
    .filter((status) => reviewStatus.includes(status.value))
    .map((status) => ({
      value: status.value,
      label: status.label,
    }));

  const handleStatusChange = (selectedOptions) => {
    if (selectedOptions.some((item) => item.label === "All")) {
      setReviewStatus(statusOptions.map((item) => item.value).concat([0])); // Use item.value
    } else if (selectedOptions.length === 0) {
      setReviewStatus([]);
    } else {
      setReviewStatus(selectedOptions.map((item) => item.value)); // Use item.value
    }
  };

  const handleFavourite = () => {
    setIsFavourite((prev) => (prev === 0 ? 1 : 0));
    setButtonClickFavourite(!buttonClickFavourite);
  };

  const handleAppointmentStatus = () => {
    setAppointmentsStatus(!appointmentStatus);
    setButtonClickStatus(!buttonClickStatus);
  };

  const tableWidth =
    appointmentsDataTableValues?.rows !== undefined && appointmentsDataTableValues?.rows?.length;

  const useStyles = makeStyles(() => ({
    tableInfo: {
      width: "100%",
      float: "left",
    },
    calender: {
      width: "100%",
      float: "right",
      border: "1px solid white",
      paddingTop: "15px",
      overflow: "auto",
    },
    "@media (min-width: 1120px)": {
      calender: {
        width: "35%",
      },
      tableInfo: {
        width: tableWidth !== false && tableWidth !== 0 ? "65%" : "100%",
      },
    },
    tabs: {
      minHeight: "0 !important",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
    tabButton: {
      backgroundColor: buttonClick ? "#FF4D00D9" : "#E7E7E7",
      color: buttonClick ? "white" : "#FF4D00D9",
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
    favButton: {
      backgroundColor: buttonClickFavourite ? "#FF4D00D9" : "#E7E7E7",
      color: buttonClickFavourite ? "white" : "#FF4D00D9",
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
    "@media (max-width: 800px)": {
      filters: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
      },
      filterTab: {
        display: "block",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    statusButton: {
      backgroundColor: buttonClickStatus ? "#FF4D00D9" : "#E7E7E7",
      color: buttonClickStatus ? "white" : "#FF4D00D9",
      padding: "5px 35px",
      border: "0",
      borderRadius: "6px",
      cursor: "pointer",
      marginRight: "1%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "@media (max-width: 800px)": {
        marginBottom: "10px",
      },
    },
  }));

  const classes = useStyles();
  const renderCampaignItems = () => {
    if (loadingCampaignItems) {
      return loader2;
    }
    if (campaign) {
      return (
        <Grid container>
          <Grid item xs={12} lg={12}>
            <Grid item xs={12} lg={12}>
              <SuiBox my={3} position="relative">
                <ThemeProvider theme={theme}>
                  <Card sx={{ borderRadius: "5px", boxShadow: "none", padding: "24px" }}>
                    <Stack
                      className={classes.filterTab}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack
                        className={classes.filters}
                        width="100%"
                        direction="row"
                        alignItems="center"
                      >
                        <Grid display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }}>
                          <Stack
                            sx={{
                              padding: "10px",
                              backgroundColor: value === 0 ? "#FF4D00D9" : "#FFFFFF",
                              borderRadius: "5px",
                              marginRight: "1%",
                              marginBottom: "1%",
                              cursor: "pointer",
                              color: value === 0 ? "#fff" : "#000000",
                              textAlign: "center",
                            }}
                            xs={{ flexGrow: 1 }}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            onClick={handleClick(0)}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" }}
                            >
                              Kanban
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{
                              padding: "10px",
                              backgroundColor: value === 1 ? "#FF4D00D9" : "#FFFFFF",
                              borderRadius: "5px",
                              marginRight: "1%",
                              marginBottom: "1%",
                              cursor: "pointer",
                              color: value === 1 ? "#fff" : "#000000",
                              textAlign: "center",
                            }}
                            xs={{ flexGrow: 1 }}
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            onClick={handleClick(1)}
                          >
                            <Typography
                              sx={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins" }}
                            >
                              Table
                            </Typography>
                          </Stack>
                        </Grid>
                        <button
                          className={classes.tabButton}
                          type="button"
                          onClick={handleCampaigns}
                        >
                          <Icon sx={{ color: buttonClick ? "white" : "#FF4D00D9" }}>
                            filter_list
                          </Icon>
                          Campaigns
                        </button>
                        <button
                          className={classes.statusButton}
                          type="button"
                          onClick={handleAppointmentStatus}
                        >
                          <Icon sx={{ color: buttonClickStatus ? "white" : "#FF4D00D9" }}>
                            filter_list
                          </Icon>
                          Status
                        </button>
                        <button
                          className={classes.favButton}
                          type="button"
                          onClick={handleFavourite}
                        >
                          <Icon sx={{ color: buttonClickFavourite ? "white" : "#FF4D00D9" }}>
                            filter_list
                          </Icon>
                          Favorite
                        </button>
                      </Stack>
                    </Stack>
                  </Card>
                </ThemeProvider>
              </SuiBox>
              <Card sx={{ borderRadius: "5px" }}>
                <CustomTabPanel value={value} index={1}>
                  <AppointmentTable
                    table={appointmentsDataTableValues}
                    canSearch
                    handleFavoriteClick={handleFavoriteClick}
                    openAppointment={handleEventClick}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    isFavourite={isFavourite}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={0}>
                  <AppointmentKanban
                    handleFavoriteClick={handleFavoriteClick}
                    openAppointment={handleEventClick}
                    reviewStatusVal={reviewStatusVal}
                    data={kanbanData}
                    customerUser={customerUser}
                    setData={setKanbanData}
                    isFavourite={isFavourite}
                    customer={customerUser}
                    reviewStatus={reviewStatus}
                    fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                  />
                </CustomTabPanel>

                <Grid item xs={12} xl={12} sx={{ height: "max-content" }}>
                  {calendarData.length > 0 ? (
                    <EventCalendarRoot
                      header={{ title: "Appointments" }}
                      initialView="dayGridMonth"
                      timeZone="UTC"
                      initialDate={today}
                      events={calendarData}
                      eventClick={handleEventClick}
                      dateClick={handleDateClick}
                      showAppointmentCalendar={showAppointmentCalendar}
                      selectable
                      editable
                      // dayMaxEvents
                      dayMaxEventRows // for all non-TimeGrid views
                      views={{
                        dayGridMonth: {
                          dayMaxEventRows: 3,
                        },
                        timeGridWeek: {
                          dayMaxEventRows: 3,
                        },
                      }}
                      height="auto"
                    />
                  ) : (
                    ""
                  )}
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <SuiBox mb={3}>
                <CalendarModal
                  openModal={open}
                  closeModal={handleClose}
                  events={filteredEvents}
                  handleEventClick={handleEventClick}
                  dateClicked={dateClicked}
                />
                <SubscriptionLink
                  openModal={isSubscriptionLinkOpen}
                  closeModal={setIsSubscriptionLinkOpen}
                  calendarLink={calendarLink}
                />
              </SuiBox>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Grid item xs={12} lg={12}>
            <SuiBox my={3} position="relative">
              <ThemeProvider theme={theme}>
                <Card
                  sx={{
                    borderRadius: "5px",
                    boxShadow: "none",
                    padding: "24px",
                    overflow: "visible",
                  }}
                >
                  <Stack
                    className={classes.filterTab}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack
                      className={classes.filters}
                      width="100%"
                      direction="row"
                      alignItems="center"
                    >
                      <Grid display={{ xs: "block", sm: "flex", md: "flex", lg: "flex" }} mr={2}>
                        <Stack
                          sx={{
                            padding: "10px",
                            backgroundColor: value === 0 ? "#FF4D00D9" : "#ffffff",
                            borderRadius: "5px",
                            marginRight: "5%",
                            marginBottom: "1%",
                            cursor: "pointer",
                            border: "1px solid #e4e4e7",
                            color: value === 0 ? "#fff" : "#000000",
                            textAlign: "center",
                          }}
                          xs={{ flexGrow: 1 }}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          onClick={handleClick(0)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-layout-grid mr-2 h-4 w-4"
                          >
                            <rect width="7" height="7" x="3" y="3" rx="1" />
                            <rect width="7" height="7" x="14" y="3" rx="1" />
                            <rect width="7" height="7" x="14" y="14" rx="1" />
                            <rect width="7" height="7" x="3" y="14" rx="1" />
                          </svg>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              marginLeft: "10px",
                            }}
                          >
                            Kanban
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            padding: "10px",
                            // backgroundColor: value === 1 ? "#FF4D00D9" : "#FFFFFF",
                            backgroundColor: value === 1 ? "#FF4D00D9" : "#ffffff",
                            borderRadius: "5px",
                            marginRight: "1%",
                            marginBottom: "1%",
                            cursor: "pointer",
                            border: "1px solid #e4e4e7",
                            color: value === 1 ? "#fff" : "#000000",
                            textAlign: "center",
                          }}
                          xs={{ flexGrow: 1 }}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          onClick={handleClick(1)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-list mr-2 h-4 w-4"
                          >
                            <line x1="8" x2="21" y1="6" y2="6" />
                            <line x1="8" x2="21" y1="12" y2="12" />
                            <line x1="8" x2="21" y1="18" y2="18" />
                            <line x1="3" x2="3.01" y1="6" y2="6" />
                            <line x1="3" x2="3.01" y1="12" y2="12" />
                            <line x1="3" x2="3.01" y1="18" y2="18" />
                          </svg>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              fontFamily: "Poppins",
                              marginLeft: "10px",
                            }}
                          >
                            Table
                          </Typography>
                        </Stack>
                      </Grid>
                      {/* <button className={classes.favButton} type="button" onClick={handleFavourite}>
                        <Icon sx={{ color: buttonClickFavourite ? "white" : "#FF4D00D9" }}>
                          filter_list
                        </Icon>
                        Favorite
                      </button> */}
                    </Stack>
                  </Stack>
                </Card>
              </ThemeProvider>
              <Grid container>
                <Grid item md={12}>
                  {campaignsList && (
                    <Campaigns
                      isSelected={isSelected}
                      filteredData={filteredData}
                      buttonClick={buttonClick}
                      campaigns={campaigns}
                      handleCampaignClick={handleCampaignClick}
                      handleAllCampaignsClick={handleAllCampaignsClick}
                    />
                  )}
                </Grid>
              </Grid>
              <Card sx={{ borderRadius: "5px", boxShadow: "none" }}>
                <div>
                  <div>
                    <Grid
                      container
                      padding={2}
                      // justifyContent="space-between"
                      alignItems="center"
                      gap={2}
                      direction={{ xs: "column", md: "row" }}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "5px",
                          paddingTop: "0.5%",
                          paddingBottom: "0.5%",
                          paddingLeft: "0.5%",
                          border: "1px solid #e4e4e7",
                        }}
                        alignItems="center"
                      >
                        <Icon sx={{ color: "#71717a", marginRight: "3px" }}>search</Icon>
                        <input
                          style={{
                            border: "0",
                            background: "white",
                            outline: "none",
                          }}
                          type="text"
                          value={search}
                          placeholder="Search Appointment"
                          onChange={onSearchChange}
                        />
                      </Stack>
                      <div className={classes.filters}>
                        <MultiSelect
                          key={campaignValue.length}
                          className={classes.datePicker}
                          placeholder="Campaign"
                          isMulti
                          options={options}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          value={displayValue}
                          onChange={handleCampaignValueChange}
                          allowSelectAll={false}
                        />
                      </div>

                      <div className={classes.filters}>
                        <MultiSelect
                          className={classes.datePicker}
                          placeholder="Status"
                          isMulti
                          options={statusOptions}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          value={statusDisplayValue}
                          onChange={handleStatusChange}
                          allowSelectAll={false}
                        />
                      </div>
                      <div>
                        <Button
                          // variant="contained"
                          style={{
                            padding: "13px",
                            border: "1px solid #e4e4e7",
                            backgroundColor: buttonClickFavourite ? "rgb(235 235 235)" : "white",
                            color: buttonClickFavourite ? "#18181b" : "#8591a4",
                            marginLeft: "10px",
                          }}
                          onClick={handleFavourite}
                        >
                          Favourite
                        </Button>
                      </div>
                      {value === 1 && (
                        <>
                          <div>
                            <Button
                              style={{
                                color: "#8591a4",
                                padding: "13px",
                                border: "1px solid #e4e4e7",
                              }}
                              onClick={handleActionClick}
                            >
                              Action
                              <ArrowDropDownIcon />
                            </Button>
                            <Menu
                              id="long-menu"
                              anchorEl={anchorEl}
                              open={openAction}
                              onClose={() => handleActionClose()}
                              PaperProps={{
                                style: {
                                  width: "25ch",
                                },
                              }}
                            >
                              <MenuItem value="export" onClick={handleExport}>
                                Export
                              </MenuItem>
                              {customerUser.customer.gohighlevel_activated === 1 &&
                                customerUser.customer.gohighlevel_api_key && (
                                  <MenuItem value="export" onClick={handleExportToGoHighLevel}>
                                    Export to goHighLevel
                                  </MenuItem>
                                )}
                              {customerUser.customer.webhook_urls_activated === 1 &&
                                customerUser.customer.webhook_urls && (
                                  <MenuItem value="export" onClick={handleExportToWebhook}>
                                    Export to webhook(s)
                                  </MenuItem>
                                )}
                              <MenuItem value="accept" onClick={handleAccept}>
                                Accept
                              </MenuItem>
                            </Menu>
                          </div>
                        </>
                      )}
                    </Grid>
                  </div>
                  <div className={classes.tableInfo}>
                    <CustomTabPanel value={value} index={1}>
                      <AppointmentTable
                        table={appointmentsDataTableValues}
                        canSearch
                        handleFavoriteClick={handleFavoriteClick}
                        openAppointment={handleEventClick}
                        isFavourite={isFavourite}
                        search={search}
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        setDropdownValue={setDropdownValue}
                        dropdownValue={dropdownValue}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={0}>
                      <AppointmentKanban
                        handleFavoriteClick={handleFavoriteClick}
                        openAppointment={handleEventClick}
                        reviewStatusVal={reviewStatusVal}
                        data={kanbanData}
                        customerUser={customerUser}
                        setData={setKanbanData}
                        customer={customerUser}
                        isFavourite={isFavourite}
                        reviewStatus={reviewStatus}
                        fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                        search={search}
                      />
                    </CustomTabPanel>
                  </div>
                  <div className={classes.calender}>
                    <ThemeProvider theme={themeGrid}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        lg={12}
                        xl={12}
                        sx={{ width: "100%", height: "100%", padding: "2px 2px 4px 2px" }}
                      >
                        <SuiVectorMapRoot
                          appointmentsOrOICLeads={appointmentsVectorMap ?? []}
                          fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                          openAppointmetModal={handleEventClick}
                          reviewStatus={reviewStatus}
                          campaignId={campaignId}
                        />
                      </Grid>
                    </ThemeProvider>
                    <div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12}
                        sx={{ width: "100%", maxHeight: "96%", padding: "2px 2px 4px 2px" }}
                      >
                        {calendarData.length > 0 ? (
                          <EventCalendar
                            header={{ title: "Appointments" }}
                            initialView="dayGridMonth"
                            timeZone="UTC"
                            initialDate={today}
                            events={calendarData}
                            eventClick={handleEventClick}
                            dateClick={handleDateClick}
                            showAppointmentCalendar={showAppointmentCalendar}
                            selectable
                            editable
                            // dayMaxEvents
                            dayMaxEventRows // for all non-TimeGrid views
                            views={{
                              dayGridMonth: {
                                dayMaxEventRows: 3,
                              },
                              timeGridWeek: {
                                dayMaxEventRows: 3,
                              },
                            }}
                            height="auto"
                          />
                        ) : (
                          ""
                        )}
                      </Grid>
                    </div>
                  </div>
                </div>
              </Card>
            </SuiBox>
          </Grid>
        </Grid>
        <Grid container sx={{ backgroundColor: "white !important", borderRadius: "6px" }}>
          <CalendarModal
            openModal={open}
            closeModal={handleClose}
            events={filteredEvents}
            handleEventClick={handleEventClick}
            dateClicked={dateClicked}
          />
          <SubscriptionLink
            openModal={isSubscriptionLinkOpen}
            closeModal={setIsSubscriptionLinkOpen}
            calendarLink={calendarLink}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar componentName="Scheduled Appointments" />
      {openAppointmentModal && selectedApointmentId ? (
        <AppointmentModal
          customerUser={customerUser}
          campaignId={campaignId}
          appointmentId={selectedApointmentId}
          open={openAppointmentModal}
          handleClose={handleCloseAppointmentModal}
          refreshList={handleRefreshList}
        />
      ) : (
        ""
      )}
      <SuiBox>
        <Grid container spacing={3} sx={{ zIndex: 100 }}>
          <Grid item xs={12} lg={12} mb={3}>
            {/* <Grid container>
              <Grid item xs={12} />

              <Grid item xs={12}>
                {loadingCampaignItems ? (
                  loader2
                ) : (
                  <ReportChart title="" description="" chart={{}} items={infoAppts} />
                )}
              </Grid>
            </Grid> */}
            {renderCampaignItems()}
          </Grid>
        </Grid>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Appointments;
