/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { Box, Card, Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ROICardChart from "examples/Charts/AnayliticsChart/ROiCardChart";
import MultiSelect from "components/MultiSelect";

const useStyles = makeStyles(() => ({
  companyInfoWrapper: {
    flex: "left",
    width: "100%",
    padding: "20px",
    marginBottom: "50px",
  },
  employeeInfoWrapper: {
    flex: "right",
    width: "100%",
    border: "1px solid white",
    padding: "20px",
    // backgroundColor: "#f8f9fa",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
  },
  "@media (min-width: 1441px)": {
    row: {
      flexDirection: "row",
      height: "95vh",
    },
    companyInfoWrapper: {
      width: "100%",
    },
    employeeInfoWrapper: {
      width: "35%",
    },
  },
  "@media (max-width: 1440px)": {
    row: {
      flexDirection: "row",
      height: "95vh",
    },
    companyInfoWrapper: {
      width: "100%",
      paddingLeft: 0,
    },
    employeeInfoWrapper: {
      width: "40%",
    },
  },
  "@media (max-width: 768px)": {
    row: {
      flexDirection: "column",
      height: "auto",
    },
    companyInfoWrapper: {
      width: "100%",
    },
    employeeInfoWrapper: {
      width: "100%",
    },
  },
  info: {
    display: "flex",
    marginBottom: "5px",
  },
  input: {
    maxWidth: "100px",
    alignItems: "center",
  },
  labelAndInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 320px)": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  datePicker: {
    width: "250px",
    marginBottom: "20px",
    "@media (max-width: 380px)": {
      width: "180px",
    },
  },
  filters: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 0,
  },
  tabs: {
    minHeight: "0 !important",
    "@media (max-width: 899px)": {
      marginBottom: "10px",
      maxWidth: "230px",
    },
  },
  btn: {
    display: "flex",
    justifyContent: "end",
    paddingTop: "7px",
    paddingRight: "30px",
    "@media (max-width: 900px)": {
      paddingRight: "0",
    },
    "@media (max-width: 320px)": {
      paddingRight: "30px",
    },
  },
}));

function DynamicCals({ customerUser, refreshData }) {
  const classes = useStyles();

  const [roiData, setRoiData] = useState(null);
  const [price, setPrice] = useState(100);
  const [averageDealSize, setAverageDealSize] = useState(15000);
  const [cardInfo, setCardInfo] = useState([]);
  const [value, setValue] = useState(0);
  const [campaignValue, setCampaignValue] = useState([]);
  const [campaignData, setCampaignData] = useState([]);
  const [style, setStyle] = useState({
    border: "0 !important",
  });
  const isSubmitDisabled = !price || !averageDealSize;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStyle({
      border: "0 !important",
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
    };
  }

  const campaignDetail = async () => {
    try {
      const url =
        value === 0
          ? `/api/customers/${customerUser.customer.id}/campaigns/appointments`
          : `/api/customers/${customerUser.customer.id}/campaigns/oic`;
      const response = await axios.get(url);

      if (response && response.data) {
        const campaigns =
          value === 0
            ? response.data.appointmentCampaigns.data
            : response.data.oicLeadCampaigns.data;

        setCampaignData(campaigns);
        setCampaignValue(campaigns.map((campaign) => campaign.id).concat([0]));
      }
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  };

  const displayValue = campaignData
    .filter((campaign) => campaignValue.includes(campaign.id))
    .map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }));

  const options = [
    ...campaignData.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    })),
  ];

  const handleCampaignValueChange = (e) => {
    if (e.some((item) => item.label === "All")) {
      setCampaignValue(campaignData.map((item) => item.id).concat([0]));
    } else {
      setCampaignValue(e.map((item) => item.value));
    }
  };

  useEffect(() => {
    campaignDetail();
  }, []);

  useEffect(() => {
    campaignDetail();
  }, [value]);

  const cardData = (data) => {
    const cardType = value === 0 ? "Appointments" : "OIC Leads";
    setCardInfo([
      {
        label: `Total ${cardType}`,
        desc: "The total number of leads generated through the specified type of campaign (e.g., email, social media, or direct mail). This metric provides insight into the volume of leads entering the sales funnel from a specific source.",
        progress: {
          content: data?.total_campaign_leads ?? 0,
        },
      },
      {
        label: "Total Campaign Deals",
        desc: "The total number of deals closed as a result of the marketing campaigns. It indicates the overall success of the campaigns in converting leads into customers.",
        progress: {
          content: data?.total_deals ?? 0,
        },
      },
      {
        label: "Total Follow Ups",
        desc: "The total number of follow-up actions taken with leads. This metric helps assess the persistence and effectiveness of efforts to engage potential customers.",
        progress: {
          content: data?.total_follow_ups ?? 0,
        },
      },
      {
        label: "Total Offers",
        desc: "The total number of offers made to leads. This measures how many potential deals reached the stage of receiving a formal proposal or offer.",
        progress: {
          content: data?.total_offers ?? 0,
        },
      },
      {
        label: `${cardType} to Deal`,
        desc: `The percentage of leads from the specified ${cardType} campaign that successfully convert into deals. This KPI evaluates the effectiveness of the ${cardType} campaign in driving sales conversions.`,
        progress: {
          content: `${data?.statistic?.["cr_campaign_lead_to_deal_%"]}%`,
        },
      },
      {
        label: `${cardType} to Follow Up`,
        desc: `The percentage of leads from the specified ${cardType} campaign that progress to the follow-up stage. It measures the initial engagement level and effectiveness in nurturing leads.`,
        progress: {
          content: `${data?.statistic?.["cr_campaign_lead_to_follow_up_%"]}%`,
        },
      },
      {
        label: `${cardType} to Offer`,
        desc: `The percentage of leads from the specified ${cardType} campaign that result in an offer being made. This metric assesses the quality of leads and the efficiency of moving them through the sales process.`,
        progress: {
          content: `${data?.statistic?.["cr_campaign_lead_to_offer_%"]}%`,
        },
      },
      {
        label: "Follow Up to Deal",
        desc: "The percentage of follow-ups that result in a closed deal. It measures the effectiveness of the follow-up process in converting potential leads into actual sales.",
        progress: {
          content: `${data?.statistic?.["cr_follow_up_to_deal_%"]}%`,
        },
      },
      {
        label: "Follow Up to Offer",
        desc: "The percentage of follow-ups that lead to an offer being extended. This indicates how successful the follow-up process is in moving leads further along the sales funnel.",
        progress: {
          content: `${data?.statistic?.["cr_follow_up_to_offer_%"]}%`,
        },
      },
      {
        label: "Offer To Deal",
        desc: "The percentage of offers that successfully convert into deals. This metric evaluates the effectiveness of closing offers and turning them into sales.",
        progress: {
          content: `${data?.statistic?.["cr_offer_to_deal_%"]}%`,
        },
      },
      {
        label: "Profitability Rating",
        desc: "A rating that assesses the profitability of marketing campaigns based on the revenue generated compared to the cost incurred. It provides an overall measure of campaign efficiency and return on investment.",
        progress: {
          content: data?.statistic?.profitability_rating
            ? `${data?.statistic?.profitability_rating} (${data?.statistic?.profitability_good_or_bad})`
            : 0,
        },
      },
      {
        label: "Predictive Deal",
        desc: "An estimate of the potential deals that are likely to close based on current data and predictive analytics. It helps in forecasting future sales performance.",
        progress: {
          content: data?.statistic?.predictive_deal,
        },
      },
      {
        label: "Net Profit",
        desc: "The percentage of total revenue that remains as profit after all expenses, including marketing, sales, and operational costs, are subtracted. This metric provides insight into the overall profitability and efficiency of the marketing efforts relative to the revenue generated.",
        progress: {
          currency: `€`,
          content: `${data?.statistic?.net_profit_10_p}`,
        },
      },
      {
        label: "Customer Acquisition Cost",
        desc: "The total cost of acquiring a new customer, including all marketing and sales expenses. This metric helps evaluate the cost-efficiency of marketing campaigns.",
        progress: {
          content: `€${data?.statistic?.customer_ac_cost}`,
        },
      },
      {
        label: "Total Revenue",
        desc: "The total revenue generated from all marketing campaigns. This KPI reflects the overall financial impact of marketing activities.",
        progress: {
          content: `€${data?.statistic?.total_revenue}`,
        },
      },
      {
        label: "ROI",
        desc: "The Return on Investment (ROI) from marketing activities. This metric shows the profitability of marketing efforts relative to their costs, providing insight into the financial effectiveness of campaigns.",
        progress: {
          content: data?.statistic?.roi,
        },
      },
    ]);
  };

  const handleStaticROIInfo = (params = null) => {
    const data = {
      campaign_type: value === 0 ? "appointments" : "oic",
      price,
      campaign_ids: campaignValue,
      average_deal_size_in_eur: averageDealSize,
      real_values: true,
      ...params,
    };
    axios
      .post(`/api/customers/${customerUser?.customer.id}/kpi/roi/list`, data)
      .then((res) => {
        if (params === null) {
          setRoiData(res?.data);
          cardData(res?.data);
        }
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  useEffect(() => {
    const params = {};
    params.price = price;

    handleStaticROIInfo();
  }, [campaignValue, refreshData]);

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            minWidth: "230px",
            padding: "0.28rem",
            background: "#EDEDED",
            borderRadius: "6px",
          },
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "0%",
            fontSize: "14px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "8px 16px 8px 18px",
              marginRight: "0%",
              zIndex: "1",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "10px",
            backgroundColor: "rgba(237, 108, 2, 1)",
            transition: "transform 0.1s ease",
            width: "100px",
            "&:hover": {
              backgroundColor: "rgb(255 138 43)",
              transform: "scale(1.1)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          input: {
            textAlign: "center",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          borderRadius: "5px",
          boxShadow: "none",
          marginTop: "10px",
        }}
      >
        <Grid
          display={{ lg: "flex", md: "flex", xs: "block" }}
          px={{ lg: 5, sm: 3, xs: 2 }}
          pt={{ lg: 5, sm: 3, xs: 3 }}
          justifyContent={{ lg: "space-between", md: "space-between" }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}
            value={value}
            onChange={handleChange}
          >
            <Tab style={value === 0 ? style : {}} label="Appointment" {...a11yProps(0)} />
            <Tab style={value === 1 ? style : {}} label="OIC" {...a11yProps(1)} />
          </Tabs>
          <div className={classes.filters}>
            <MultiSelect
              className={classes.datePicker}
              placeholder="Campaign"
              isMulti
              options={options}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={displayValue}
              onChange={handleCampaignValueChange}
              allowSelectAll
            />
          </div>
        </Grid>
        <div className={classes.row}>
          <div className={classes.companyInfoWrapper}>
            <Grid container spacing={1} p={2} pr={0} columns={{ xs: 4, sm: 4, md: 12 }}>
              <Grid item xs={12} sm={6} md={5}>
                <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                  <div className={classes.labelAndInput}>
                    <Typography>Price</Typography>
                    <TextField
                      className={classes.input}
                      type="number"
                      variant="standard"
                      defaultValue={price}
                      onChange={(e) => {
                        const priceValue = Number(e.target.value);
                        if (value >= 0) {
                          setPrice(priceValue);
                        } else {
                          e.target.value = 0;
                        }
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <Box mr={{ sm: 0, xs: 0, md: 4 }}>
                  <div className={classes.labelAndInput}>
                    <Typography>Average Deal Size</Typography>
                    <TextField
                      className={classes.input}
                      type="number"
                      variant="standard"
                      defaultValue={averageDealSize}
                      onChange={(e) => {
                        const numValue = Number(e.target.value);
                        if (numValue >= 0) {
                          setAverageDealSize(numValue);
                        } else {
                          e.target.value = 0;
                        }
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <div className={classes.btn}>
                  <Button
                    variant="contained"
                    disabled={isSubmitDisabled}
                    onClick={() => handleStaticROIInfo()}
                    style={{
                      backgroundColor: "rgb(235 235 235)",
                      color: "rgba(237, 108, 2, 1)",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Grid>
            </Grid>
            {cardInfo.length !== 0 && (
              <Grid item xs={12} md={12} mt={3} pb={{ xs: "20px" }}>
                <Box
                  sx={{
                    maxHeight: "700px",
                    overflowY: "auto",
                  }}
                >
                  <ROICardChart
                    color="#444"
                    title=""
                    description={cardInfo.length !== 0 ? roiData?.statistic?.advice : ""}
                    chart={{}}
                    items={cardInfo}
                  />
                </Box>
              </Grid>
            )}
          </div>
        </div>
      </Card>
    </ThemeProvider>
  );
}

export default DynamicCals;
DynamicCals.propTypes = {
  customerUser: PropTypes.any.isRequired,
  campaignValue: PropTypes.array.isRequired,
  refreshData: PropTypes.bool.isRequired,
};
