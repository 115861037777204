/* eslint-disable no-unused-vars */
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useEffect, useState } from "react";
import { Card, CircularProgress, Stack } from "@mui/material";
import { dateToYMD } from "common/helpers";
import Swal from "sweetalert2";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@mui/material/Tab";
import AppKanban from "components/authentication_v2/AppKanban";
import OicKanbanData from "components/authentication_v2/OICKanbanData";
import MultiSelect from "components/MultiSelect";

const useStyles = makeStyles(() => ({
  datePicker: {
    width: "250px",
    marginBottom: "20px",
    "@media (max-width: 380px)": {
      width: "180px",
    },
  },
  filters: {
    display: "block",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 0,
  },
  tabs: {
    minHeight: "0 !important",
    "@media (max-width: 899px)": {
      marginBottom: "10px",
      maxWidth: "230px",
    },
  },
}));

const KanbanInfo = ({ customerUser }) => {
  const history = useHistory();
  const [today, setToday] = useState(new Date());
  const [oicCampaigns, setOicCampaigns] = useState([]);
  const [campaignId, setCampaignId] = useState(0);
  const [kanbanData, setKanbanData] = useState([]);
  const [oicKanbanData, setOicKanbanData] = useState([]);
  const [loadingCampaignItems, setLoadingCampaignItems] = useState(true);
  const [campaignData, setCampaignData] = useState([]);
  const [campaignValue, setCampaignValue] = useState([]);

  const handleOpenAppointment = (pAppointmentId) => {
    history.push({
      pathname: "/enrichment",
      state: {
        appointmentIdParam: pAppointmentId,
        customerUser: { customerUser },
        campaignId: { campaignId },
        appointmentId: { pAppointmentId },
      },
    });
    // setSelectedApointmentId(pAppointmentId);
    // setOpenAppointmentModal(true);
  };

  const handleOpenOICModal = (pOICLeadId) => {
    history.push({
      pathname: "/enrichment-oic",
      state: {
        appointmentIdParam: pOICLeadId,
        customerUser: { customerUser },
        campaignId: { campaignId },
        appointmentId: { pOICLeadId },
      },
    });
  };

  function CustomTabPanel(props) {
    const { children, value, index } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3, pt: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [value, setValue] = useState(0);
  const [style, setStyle] = useState({
    border: "0 !important",
  });

  useEffect(() => {
    const tabIndex = localStorage.getItem("activeFollowUpTabIndex");
    if (tabIndex !== null) {
      setValue(parseInt(tabIndex, 10));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("activeFollowUpTabIndex", value.toString());
  }, [value]);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStyle({
      border: "0 !important",
    });
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
    };
  }

  const campaignDetail = async () => {
    try {
      const url =
        value === 0
          ? `/api/customers/${customerUser.customer.id}/campaigns/appointments`
          : `/api/customers/${customerUser.customer.id}/campaigns/oic`;
      const response = await axios.get(url);

      if (response && response.data) {
        const campaigns =
          value === 0
            ? response.data.appointmentCampaigns.data
            : response.data.oicLeadCampaigns.data;

        setCampaignData(campaigns);
        setCampaignValue(campaigns.map((campaign) => campaign.id).concat([0]));
      }
    } catch (error) {
      Swal.fire("Error!", error.message, "error");
    }
  };

  const handleCampaignValueChange = (e) => {
    if (e.some((item) => item.label === "All")) {
      setCampaignValue(campaignData.map((item) => item.id).concat([0]));
    } else {
      setCampaignValue(e.map((item) => item.value));
    }
  };

  useEffect(() => {
    campaignDetail();
  }, []);

  useEffect(() => {
    campaignDetail();
  }, [value]);

  CustomTabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          flexContainer: {
            minWidth: "230px",
            padding: "0.28rem",
            background: "#EDEDED",
            borderRadius: "6px",
          },
          indicator: {
            display: "none",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            backgroundColor: "#EDEDED",
            color: "#5F5F5F",
            fontFamily: "Poppins",
            padding: "8px 16px 8px 18px",
            borderRadius: "6px",
            textTransform: "none",
            marginRight: "0",
            fontSize: "14px",
            fontWeight: "600",
            minHeight: "5px",
            "&.Mui-selected": {
              backgroundColor: "#FF4D00D9",
              color: "#fff",
              fontFamily: "Poppins",
              borderRadius: "6px",
              padding: "8px 16px 8px 18px",
              marginRight: "0",
              fontSize: "13px",
              fontWeight: "600",
            },
          },
        },
      },
    },
  });

  const [reviewStatus, setReviewStatus] = useState(["info", "warning", "success", "error"]);
  const [reviewStatusVal, setReviewStatusVal] = useState({
    total: 0,
    info: 0,
    warning: 0,
    success: 0,
    error: 0,
  });
  const [resset, setResset] = useState({
    cols: null,
    res: null,
  });

  const fetchRes = (cols, res, pReviewStatus) => {
    setOicKanbanData(null);

    if (value === 0) {
      setKanbanData(null);
      let datx = [];
      if (res) {
        datx = res?.data?.data;
      }
      const itemsx = datx.map((d) => d.class_name).flat();
      const rSet = res?.data?.data.filter(
        (rv) => pReviewStatus.indexOf(rv.class_name) > -1 || pReviewStatus === undefined
      );
      let info = 0;
      let warning = 0;
      let success = 0;
      let error = 0;
      for (let i = 0; i < itemsx.length; i += 1) {
        // eslint-disable-next-line default-case
        switch (itemsx[i]) {
          case "info":
            info += 1;
            break;
          case "warning":
            warning += 1;
            break;
          case "success":
            success += 1;
            break;
          case "error":
            error += 1;
            break;
        }
      }
      const total = info + warning + success + error;
      setReviewStatusVal({
        total,
        info,
        warning,
        success,
        error,
      });
      setKanbanData(rSet);
    } else {
      const rSet = res?.data?.data;
      setOicKanbanData(rSet);
    }

    setLoadingCampaignItems(false);
  };

  useEffect(() => {
    if (resset.cols && resset.res) {
      fetchRes(resset.cols, resset.res, reviewStatus);
    }
    return () => {};
  }, [reviewStatus]);

  const fetchCampaignAppointmentsOrOics = (pCampaignId = 0, pReviewStatus = null) => {
    if (value === 0) {
      axios
        .get(
          `/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/appointments/fields`
        )
        .then((r) => {
          const cols = r.data.data;
          axios
            .get(
              `/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/appointments?is_favorite=1`
            )
            .then((res) => {
              setResset({
                cols,
                res,
              });
              fetchRes(cols, res, pReviewStatus);
            })
            .catch(() => {
              fetchRes(cols, null, pReviewStatus);
            });
        })
        .catch(() => {});
    } else {
      axios
        .get(`/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/oic/fields`)
        .then((r) => {
          const cols = r.data.data;

          axios
            .get(
              `/api/customers/${customerUser.customer.id}/campaigns/${pCampaignId}/oic?is_favorite=1`
            )
            .then((res) => {
              setResset({
                cols,
                res,
              });
              fetchRes(cols, res);
            })
            .catch(() => {
              fetchRes(cols, null);
            });
        })
        .catch(() => {});
    }
  };

  const fetchOicCampaigns = () => {
    setLoadingCampaignItems(true);
    axios
      .get(`/api/customers/${customerUser.customer.id}/campaigns/oic`)
      .then((res) => {
        setOicCampaigns(res.data);
      })
      .catch(() => {
        setOicCampaigns([]);
      });
  };

  const queryParams = new URLSearchParams(window.location.search);
  const appointmentIdParam = queryParams.get("appointmentId");

  useEffect(() => {
    if (appointmentIdParam !== undefined && appointmentIdParam) {
      if (value === 0) {
        handleOpenAppointment(appointmentIdParam);
      } else {
        handleOpenOICModal(appointmentIdParam);
      }
    }
    return () => {};
  }, [appointmentIdParam]);

  useEffect(() => {
    setToday(dateToYMD(new Date()));

    return () => {};
  }, [today]);

  useEffect(() => {
    fetchOicCampaigns();

    return () => {};
  }, [null, customerUser, value]);

  const isCampaignCheck = campaignValue[0] === "*";

  useEffect(() => {
    if (campaignValue.length > 1) {
      if (isCampaignCheck) {
        setCampaignId(campaignValue[1]);
        fetchCampaignAppointmentsOrOics(campaignValue[1], reviewStatus);
      } else {
        setCampaignId(null);
        fetchCampaignAppointmentsOrOics(0, reviewStatus);
      }
    } else if (campaignValue.length === 1) {
      setCampaignId(campaignValue[0]);
      fetchCampaignAppointmentsOrOics(campaignValue[0], reviewStatus);
    } else {
      setCampaignId(null);
      fetchCampaignAppointmentsOrOics(0, reviewStatus);
    }
  }, [campaignValue, value]);

  const handleFavoriteClick = (event) => {
    if (value === 0) {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${event.id}/update-favorite-status`,
          {}
        )
        .then(() => {
          if (campaignId === null) {
            fetchCampaignAppointmentsOrOics(0, reviewStatus);
          } else {
            fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
          }
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    } else {
      axios
        .post(
          `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${event.id}/update-favorite-status`
        )
        .then(() => {
          if (campaignId === null) {
            fetchCampaignAppointmentsOrOics(0, reviewStatus);
          } else {
            fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
          }
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
        });
    }
  };

  const handleEventClick = (event) => {
    if (event.class_name.includes("info")) {
      Swal.fire({
        title: "Appointment Confirmation",
        text: "Do you want to confirm the appointment?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/appointments/${event.id}/update-review-status`,
              context
            )
            .then(() => {
              handleOpenAppointment(event.id);
              fetchCampaignAppointmentsOrOics(campaignId, reviewStatus);
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else if (event.class_name.includes("error")) {
      Swal.fire("Warning!", "You are not allowed to access the overview page", "warning");
    } else {
      handleOpenAppointment(event.id);
    }
  };

  const handleOICEventClick = (event) => {
    if (event.class_name.includes("event-info")) {
      Swal.fire({
        title: "OICLead Confirmation",
        text: "Do you want to confirm the oicLead?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm it!",
      }).then((result) => {
        if (result.isConfirmed) {
          const context = {
            is_confirmation_form: "yes",
            review_status: "accepted",
          };
          axios
            .post(
              `/api/customers/${customerUser.customer.id}/campaigns/${campaignId}/oic/${event.id}/update-review-status`,
              context
            )
            .then(() => {
              handleOpenOICModal(event.id);
              fetchCampaignAppointmentsOrOics(campaignId);
            })
            .catch((err) => {
              Swal.fire("Error!", err.message, "error");
            });
        }
      });
    } else {
      handleOpenOICModal(event.id);
    }
  };
  const loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="40vh"
      width="100%"
    >
      <CircularProgress color="warning" /> Loading..
    </Stack>
  );

  const displayValue = campaignData
    .filter((campaign) => campaignValue.includes(campaign.id))
    .map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }));

  const options = [
    ...campaignData.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    })),
  ];

  const renderCampaignItems = () => {
    if (loadingCampaignItems) {
      // return loader2;
    }
    return (
      <>
        <ThemeProvider theme={theme}>
          <Card
            sx={{
              borderRadius: "5px",
              boxShadow: "none",
              overflow: "visible",
            }}
          >
            <>
              <div>
                <SuiBox position="relative">
                  <Card sx={{ borderRadius: "5px", boxShadow: "none", overflow: "visible" }}>
                    <Grid
                      display={{ lg: "flex", md: "flex", xs: "block" }}
                      px={{ lg: 5, sm: 3, xs: 2 }}
                      pt={{ lg: 5, sm: 3, xs: 2 }}
                      justifyContent={{ lg: "space-between", md: "space-between" }}
                    >
                      <Tabs
                        // lg={10}
                        // xs={8}
                        // sm={5}
                        variant="scrollable"
                        scrollButtons="auto"
                        className={classes.tabs}
                        value={value}
                        onChange={handleChange}
                      >
                        <Tab
                          style={value === 0 ? style : {}}
                          label="Appointment"
                          {...a11yProps(0)}
                        />
                        <Tab style={value === 1 ? style : {}} label="OIC" {...a11yProps(1)} />
                      </Tabs>
                      <div className={classes.filters}>
                        <MultiSelect
                          className={classes.datePicker}
                          placeholder="Campaign"
                          isMulti
                          options={options}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          value={displayValue}
                          onChange={handleCampaignValueChange}
                          allowSelectAll
                        />
                      </div>
                    </Grid>

                    {loadingCampaignItems ? (
                      loader2
                    ) : (
                      <>
                        <CustomTabPanel value={value} index={0}>
                          {kanbanData && kanbanData.length > 0 ? (
                            <AppKanban
                              handleFavoriteClick={handleFavoriteClick}
                              openAppointment={handleEventClick}
                              reviewStatusVal={reviewStatusVal}
                              data={kanbanData}
                              campaignId={campaignId}
                              customerUser={customerUser}
                              setData={setKanbanData}
                              customer={customerUser}
                              reviewStatus={reviewStatus}
                              fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                            />
                          ) : null}
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                          {oicKanbanData && oicKanbanData.length > 0 ? (
                            <OicKanbanData
                              data={oicKanbanData}
                              canSearch
                              campaignId={campaignId}
                              handleFavoriteClick={handleFavoriteClick}
                              openAppointment={handleOICEventClick}
                              customerUser={customerUser}
                              fetchCampaignAppointmentsOrOics={fetchCampaignAppointmentsOrOics}
                            />
                          ) : null}
                        </CustomTabPanel>
                      </>
                    )}
                  </Card>
                </SuiBox>
              </div>
            </>
          </Card>
        </ThemeProvider>
      </>
    );
  };

  return (
    <SuiBox>
      <Grid container spacing={3} sx={{ zIndex: 100 }}>
        <Grid item xs={12} lg={12} mb={3}>
          {renderCampaignItems()}
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default KanbanInfo;
KanbanInfo.propTypes = {
  customerUser: PropTypes.any.isRequired,
};
