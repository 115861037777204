import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";
import { Box, Card, CardContent, Grid, Collapse } from "@mui/material";
import PropTypes from "prop-types";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";

function LeadInfo({ appointment }) {
  const useStyles = makeStyles(() => ({
    header1: {
      padding: "1%",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#FFFFFF",
      backgroundColor: "#FF7F50",
      textAlign: "center",
      cursor: "pointer",
    },
    header: {
      padding: "1%",
      fontSize: "15px",
      fontWeight: "bold",
      color: "#000000",
      backgroundColor: "#F0F0F0",
      textAlign: "center",
    },
    downArrow: {
      width: 0,
      height: 0,
      borderLeft: "25px solid transparent", // Left part of the triangle
      borderRight: "25px solid transparent", // Right part of the triangle
      borderTop: "50px solid black", // Visible part of the triangle (downward arrow)
      marginTop: "10px", // Space between arrow and content
      display: "inline-block", // To keep it inline with the text
    },
    cardContent: { margin: "0", padding: "0" },
    card: {
      borderRadius: "0px 0px 3.5px 3.5px",
      marginBottom: "5%",
    },
    typographyLabelStyle: {
      fontSize: "14px",
      color: "#626262",
      marginBottom: "7px",
    },
    typographyBodyStyle: {
      fontWeight: "400",
      fontSize: "14px",
      color: "black",
    },
    boxStyle: {
      paddingTop: "10px",
      paddingLeft: "20px",
      paddingBottom: "20px",
    },
    gridStyle: {
      padding: "5px 30px 30px 30px",
    },
    listItemTextStyle: {
      margin: "10px",
    },
    mainGridStyle: {
      marginTop: "0px",
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.header1}
            variant="h6"
            onClick={handleToggle}
            style={{ display: "flex", alignItems: "center" }}
          >
            <span px={3}>&nbsp;&nbsp;&nbsp;Campaign Information</span>
            {!open && (
              <div
                style={{
                  width: "0",
                  height: "0",
                  borderLeft: "6px solid transparent",
                  borderRight: "6px solid transparent",
                  borderTop: "8px solid white",
                  marginLeft: "10px", // Add space between text and triangle
                }}
              />
            )}
          </Typography>
          <Collapse in={open}>
            <Box className={classes.body}>
              <Grid container className={classes.mainGridStyle} spacing={3}>
                <Grid item sx={12} mx={3}>
                  <Typography className={classes.typographyBodyStyle} component="div">
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: appointment.campaign_description,
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.header} variant="h6">
            Lead Information
          </Typography>
          <Box className={classes.body}>
            <Grid container className={classes.mainGridStyle}>
              {appointment.lead_raw_fields
                ? appointment.lead_raw_fields.map((res) =>
                    res.data !== undefined && res.data && res.data.length > 0 ? (
                      <>
                        <Box className={classes.boxStyle}>
                          <Typography className={classes.typographyLabelStyle} component="div">
                            {res.field_group_name}
                          </Typography>
                        </Box>
                        <Grid container className={classes.gridStyle} spacing={3}>
                          {res.data.map((r) => (
                            <Grid item sx={12} sm={4} md={4}>
                              <ListItemText
                                primary={
                                  <Typography
                                    className={classes.typographyLabelStyle}
                                    component="div"
                                  >
                                    {r.name}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    className={classes.typographyBodyStyle}
                                    component="div"
                                  >
                                    {r.value}
                                  </Typography>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}

LeadInfo.propTypes = {
  appointment: PropTypes.object.isRequired,
};

export default LeadInfo;
