import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography, Pagination, Stack, Icon, Grid, CircularProgress } from "@mui/material";
import TableSort from "components/authentication_v2/TableSort";
import axios from "axios";
import "./index.css";
import ReactHtmlParser from "react-html-parser";
// import UserCampaignsModal from "layouts/pages/account/profile/components/EmployeeInfo/UserCampaignsModal";
import DateTimeFormatter from "components/authentication_v2/DateTimeFormatter";
import { Box } from "@material-ui/core";
// import ActivityLogIcon from "layouts/crm_dashboard/icons/ActivityLogIcon";

function ActivityLogs({ customerId }) {
  const [loadingInfoTable, setLoadingInfoTable] = useState(false);

  const [activities, setActivities] = useState({
    data: [],
    links: [],
    meta: {},
  });
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const refreshActivitiesDataTable = () => {
    setLoadingInfoTable(true);
    setActivities(null);
    axios
      .get(`/api/customers/${customerId}/dashboard/activity-logs`, {
        params: {
          search: searchQuery,
          page,
        },
      })
      .then((res) => res.data)
      .then((res) => {
        setActivities(res);
        setRowsPerPage(res.meta.per_page);
      })
      .catch((error) => {
        console.error("Err", error.message);
      })
      .finally(() => {
        setLoadingInfoTable(false);
      });
  };

  useEffect(() => {
    refreshActivitiesDataTable();

    return () => {};
  }, [page]);
  const useStyles = makeStyles(() => ({
    TableContainer: {
      padding: "0 2%",
    },
    tableHead: {
      // borderBottom: "0",
      margin: "0",
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "Poppins",
      color: "#010101",
    },
    table: {
      fontSize: "11px",
      fontFamily: "Poppins",
      fontWeight: "400",
      borderTop: "1px solid #5F5F5F26",
      color: "#4A4A4A",
    },

    // tableRow: { borderBottom: "1px solid #50555C" },
    pagination: { float: "right", padding: "2% 0" },
    itemStatus: {
      borderRadius: "5px",
      padding: "4% 0",
      fontSize: "12px",
      cursor: "pointer",
      textAlign: "center",
    },
    dialogPaper: { boxShadow: "none" },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "lightgrey",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            color: "#626161",
            fontSize: "12px",
            fontWeight: "500",
            fontFamily: "Poppins",
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: "#010101",
              fontSize: "14px",
              fontWeight: "700",
              fontFamily: "Poppins",
            },
          },
        },
      },
    },
  });

  const Loader2 = (
    <Stack
      alignItems="center"
      className="loader"
      display="flex"
      justifyContent="center"
      height="50vh"
      width="100%"
    >
      <CircularProgress /> Loading..
    </Stack>
  );

  const [sortingOrderBy, setSortingOrderBy] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const handleDateTimeSort = () => {
    if (sortingOrderBy === "created_at") {
      setSortingOrder(sortingOrder === "asc" ? "desc" : "asc");
    } else {
      setSortingOrderBy("created_at");
      setSortingOrder("asc");
    }
  };
  const emptyRows =
    rowsPerPage -
    Math.min(
      rowsPerPage,
      activities && activities.data ? activities.data.length : null - page * rowsPerPage
    );

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  function handleSearch(item) {
    const nameMatches = item.description.toLowerCase().includes(searchQuery.toLowerCase());
    const createdAt = item.created_at.toLowerCase().includes(searchQuery.toLowerCase());

    return nameMatches || createdAt;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container sx={{ position: "relative", overflowY: "auto" }}>
        <Grid item lg={12} md={12} sm={12} xs={10}>
          <Stack
            sx={{
              flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
              alignItems: { xs: "flex-start", sm: "center", md: "center", lg: "center" },
            }}
            justifyContent="space-between"
          >
            {/* <Typography
              fontSize="15px"
              fontWeight="700"
              color="#f75e2f"
              justifyContent="center"
              textAlign="center"
              mb={2}
              ml={3}
              sx={{ cursor: "pointer" }}
              sorted={sortingOrderBy === "description" ? sortingOrder : "none"}
              onClick={handleDateTimeSort}
            >
              <ActivityLogIcon color="#FF4D00" width={2} height={2} /> Activity Feeds
            </Typography> */}
            {/* <Stack
              sx={{
                backgroundColor: "#F7F7F7",
                borderRadius: "5px",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "10px",
                // width: "17%",
              }}
              mb={4}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
              <input
                style={{
                  border: "0",
                  background: "transparent",
                  outline: "none",
                }}
                type="text"
                value={searchQuery}
                placeholder="Search..."
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Stack> */}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {loadingInfoTable ? (
            Loader2
          ) : (
            <Box padding={2}>
              <TableContainer className={classes.TableContainer}>
                <Table>
                  {/* <TableHead> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <TableSort
                        // sx={{ border: "0 !important" }}
                        align="left"
                        style={{ borderBottom: "none !important" }}
                        sorted={sortingOrderBy === "description" ? sortingOrder : "none"}
                        onClick={handleDateTimeSort}
                      >
                        Activity Feeds
                      </TableSort>
                    </div>

                    <div>
                      <Stack
                        sx={{
                          backgroundColor: "#F7F7F7",
                          borderRadius: "5px",
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          paddingLeft: "10px",
                          // width: "17%",
                        }}
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                      >
                        <Icon sx={{ color: "#FF4D00D9" }}>search</Icon>
                        <input
                          style={{
                            border: "0",
                            background: "transparent",
                            outline: "none",
                          }}
                          type="text"
                          value={searchQuery}
                          placeholder="Search..."
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </Stack>
                    </div>
                  </div>
                  {/* </TableHead> */}
                  <div style={{ maxHeight: 600, overflowY: "auto" }}>
                    <TableBody>
                      {activities && activities.data
                        ? (rowsPerPage > 0
                            ? stableSort(
                                activities.data.filter(handleSearch),
                                getComparator(sortingOrder, sortingOrderBy)
                              )
                            : stableSort(
                                activities.data,
                                getComparator(sortingOrder, sortingOrderBy)
                              )
                          ).map((item) => (
                            <>
                              <Box width="100%" padding={2}>
                                <Stack
                                  p={2}
                                  direction="column"
                                  justifyContent="space-between"
                                  sx={{ border: "1px solid #e4e4e7", borderRadius: "5px" }}
                                >
                                  <Typography
                                    key={item.id}
                                    sx={{
                                      fontFamily: "Poppins",
                                      color: "#71717a",
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {item.description ? ReactHtmlParser(item.description) : null}
                                    <br />
                                    <DateTimeFormatter
                                      mr={3}
                                      dateTime={item.created_at ? item.created_at : null}
                                    />
                                  </Typography>
                                  {/* {iconSrc && <img src={iconSrc} alt="Icon" />} */}
                                </Stack>
                              </Box>
                              {/* <TableRow key={item.id}>
                                <TableCell className={classes.table} scope="row">
                                  <DateTimeFormatter
                                    mr={3}
                                    dateTime={item.created_at ? item.created_at : null}
                                  />{" "}
                                  - {item.description ? ReactHtmlParser(item.description) : null}
                                </TableCell>
                              </TableRow> */}
                            </>
                          ))
                        : null}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 43 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </div>
                </Table>
                <Pagination
                  className={classes.pagination}
                  count={Math.ceil(
                    activities && activities.meta
                      ? activities.meta.total / activities.meta.per_page
                      : 0
                  )}
                  page={page + 1}
                  onChange={(event, newPage) => handleChangePage(event, newPage - 1)}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

ActivityLogs.propTypes = {
  customerId: PropTypes.number.isRequired,
};

export default ActivityLogs;
