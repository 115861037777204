/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { saveAs } from "file-saver";
import { CircularProgress, Box, Select, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { dateToYMD } from "common/helpers";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import SuiBox from "components/SuiBox";
import SuiSelect from "components/SuiSelect";
import AppointmentInfo from "./AppointmentInfo";
import LeadInfo from "./LeadInfo";
import Map from "./Map";
import NoteAppointment from "../NoteAppointment";
import InternalNote from "./InternalNote/InternalNote";

function Appointment({ customerUser, campaignId, appointmentId }) {
  const [appointment, setAppointment] = useState(null);
  const [downloadCounter, setDownloadCounter] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [today, setToday] = useState(dateToYMD(new Date()));
  const [loading, setLoading] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [loadingXls, setLoadingXls] = useState(false);
  // const [isOpen, setIsOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("favorite");
  const [isHovering, setIsHovering] = useState(false);
  const [rate, setRate] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}`
      )
      .then(async (res) => {
        const appointmentRes = res.data.data;

        setAppointment(appointmentRes);
        setRate(appointmentRes.client_rating);
        setDownloadCounter(appointmentRes.download_counter.toString());
        setToday(dateToYMD(new Date(appointmentRes.appointment_at)));

        if (!appointmentRes.is_prospect_contacted) {
          Swal.fire({
            title: "Did you contact the prospect?",
            text: "You won't be able to revert this!",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, prospect was contacted!",
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire("Congrats!", "Your got in touch with the prospect!", "success");
              axios
                .get(
                  `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}/prospect-contacted`
                )
                .then(() => {
                  axios
                    .get(
                      `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}`
                    )
                    .then((resA) => {
                      const appointmentResVal = resA.data.data;
                      setAppointment(appointmentResVal);
                    })
                    .catch(() => {});
                })
                .catch(() => {});
            }
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
        setLoading(false);
      });
    return () => {
      setAppointment(null);
    };
  }, [appointmentId]);

  const handleDownloadPdf = () => {
    setLoadingPdf(true);

    const payload = {
      is_oic_lead_approved_pdf: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}/download-oic-lead-pdf`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.pdf`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
          setLoadingPdf(false);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
          setLoadingPdf(false);
        });
    }
  };

  const handleDownloadCsv = () => {
    setLoadingCsv(true);
    const payload = {
      is_oic_lead_approved_csv: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}/download-oic-lead-csv`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.csv`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
          setLoadingCsv(false);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
          setLoadingCsv(false);
        });
    }
  };

  const handleDownloadXls = () => {
    setLoadingXls(true);
    const payload = {
      is_oic_lead_approved_csv: "YES",
    };

    if (appointment.customer_write_permission) {
      axios
        .post(
          `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}/download-oic-lead-xls`,
          payload,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const filename = `export_${
            appointment.campaign_lead.lead_identificator
          }_appointment_${new Date().toJSON().slice(0, 16).replace(":", "_")}.xls`;
          saveAs(res.data, filename);
          Swal.fire("Success!", res.data.message, "success");
          setDownloadCounter(parseInt(downloadCounter, 10) + 1);
          setLoadingXls(false);
        })
        .catch((err) => {
          Swal.fire("Error!", err.message, "error");
          setLoadingXls(false);
        });
    }
  };

  const changeRate = (pRate) => {
    axios
      .post(
        `/api/customers/${customerUser.customerUser.customer.id}/campaigns/${campaignId.campaignId}/oic/${appointmentId}/rate`,
        {
          rate: pRate,
        }
      )
      .then(() => {
        // Swal.fire("Success!", res.data.message, "success");
      })
      .catch((err) => {
        Swal.fire("Error!", err.message, "error");
      });
  };

  const handleRate = (e, value) => {
    if (value === rate) return;
    setRate(value);
    changeRate(value);
  };

  // const handleChatOpen = () => {
  //   setIsOpen(true);
  // };
  const useStyles = makeStyles(() => ({
    slider: { width: "60%", padding: "3% 0" },
    mainGridStyle: {
      marginTop: "25px",
      marginBottom: "25px",
    },
  }));
  const classes = useStyles();
  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          circle: {
            color: "#FF7701",
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          track: {
            backgroundColor: "#FF7701",
          },
          root: {
            color: "orange",
          },
          thumb: {
            color: "white",
          },
          valueLabel: {
            backgroundColor: "#FF7701",
          },
          markLabel: {
            marginTop: "5%",
          },
          mark: {
            height: "20%",
            color: "black",
            marginTop: "4%",
          },
        },
      },
    },
  });

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(true);
  };

  const handleSubmit = (value) => {
    const payload = {
      review_status: value,
    };
    axios
      .post(
        `/api/customers/${customerUser?.customerUser?.customer?.id}/campaign-leads/${appointment?.campaign_lead?.id}/review-status`,
        payload
      )
      .then(() => {
        Swal.fire("Success!", "Review Status Changed Successfully", "success");
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          Swal.fire("Access Denied", "You can not access the page.", "error");
        } else {
          Swal.fire("Error!", err.message, "error");
        }
      });
  };

  const options = [
    { value: "favorite", label: "Favorite" },
    { value: "follow_up", label: "Follow Up" },
    { value: "offer", label: "Offer" },
    { value: "deal", label: "Deal" },
  ];

  const selectedOption = options.find((option) => option.value === selectValue);

  const handleValueChange = (event) => {
    setSelectValue(event.value);
    handleSubmit(event.value);
  };

  useEffect(() => {
    if (appointment?.campaign_lead?.customer_review_status) {
      setSelectValue(
        appointment.campaign_lead.customer_review_status === null
          ? "favorite"
          : appointment.campaign_lead.customer_review_status
      );
    }
  }, [appointment]);

  return (
    <>
      {loading ? (
        <Box mt="15%" display="flex" alignItems="center" justifyContent="center">
          <ThemeProvider theme={theme}>
            <CircularProgress size="5%" />
          </ThemeProvider>
        </Box>
      ) : (
        <>
          {appointment ? (
            <Grid container p={2} spacing={3}>
              {/* {appointment.customer_write_permission && (
                <>
                  <ChatSVG onClick={handleChatOpen} />
                  {isOpen && (
                    <Chat close={setIsOpen} appointment={appointment} customerUser={customerUser} />
                  )}
                </>
              )} */}
              <Grid item sm={12} lg={7}>
                {/* <Box onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}> */}
                <AppointmentInfo
                  handleDownloadPdf={handleDownloadPdf}
                  handleDownloadCsv={handleDownloadCsv}
                  handleDownloadXls={handleDownloadXls}
                  appointment={appointment}
                  loadingPdf={loadingPdf}
                  loadingCsv={loadingCsv}
                  loadingXls={loadingXls}
                />
                {/* </Box> */}
                <LeadInfo appointment={appointment} />
              </Grid>
              <Grid item sm={12} lg={5}>
                <Box>
                  <Grid item sm={12} md={12}>
                    {appointment && appointment?.is_favorite === 1 && (
                      <Grid item xs={12} lg={4} sm={6} mb={3} sx={{ zIndex: 1000 }}>
                        <Grid item xs={12} sm={12}>
                          <SuiBox p={1}>
                            <SuiSelect
                              className={classes.suiSelectStyle}
                              style={{ zIndex: 1000 }}
                              placeholder="Type here..."
                              defaultValue={selectedOption}
                              onChange={handleValueChange}
                              options={options}
                            />
                          </SuiBox>
                        </Grid>
                      </Grid>
                    )}
                    <NoteAppointment appointment={appointment} />
                  </Grid>
                  {appointment.customer_write_permission && (
                    <>
                      <Grid item sm={12} md={12}>
                        <InternalNote appointment={appointment} customerUser={customerUser} />
                      </Grid>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          ) : null}
        </>
      )}
    </>
  );
}

Appointment.propTypes = {
  customerUser: PropTypes.object.isRequired,
  campaignId: PropTypes.object.isRequired,
  appointmentId: PropTypes.number.isRequired,
};

export default Appointment;
